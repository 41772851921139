// Fonts
// ------------------------------------------------------------

// Fonts size and line height defaults for our calculations

$base-font-size								: 16px; 				// Sets the browser default size. Shouldn't need to change this.
$base-font-weight                           : 300;
$base-font-bold-weight                      : bold;
$base-line-height							: 6px;					// Baseline grid. Set to a sensible, small size.
$round-to-nearest-half-line					: false;  				// Round the line height to the nearest half line height. Off by default
$rhythm-unit                                : "rem";



$font-family-sans-serif:  "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       "Raleway", Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-base: 14px !default;
$font-size-base-rem: 1rem;
$font-size-root: $font-size-base !default;

$font-size-lg:  ceil(($font-size-base * 1.25)) !default;
$font-size-lg-rem:  ceil(($font-size-base-rem * 1.25)) !default;
$font-size-sm:  ceil(($font-size-base * 0.8)) !default;
$font-size-sm-rem:  ceil(($font-size-base-rem * 0.65)) !default;
$font-size-xs:  ceil(($font-size-base * 0.95)) !default;
$font-size-xs-rem:  ceil(($font-size-base-rem * 0.95)) !default;

$line-height-base: 1.5 !default;

$font-size-h1:            ceil(($font-size-base * 1.7)) !default; // ~36px
$font-size-h2:            ceil(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    300 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba(0,0,0,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25) !default;
$nested-kbd-font-weight: bold !default;

$list-inline-padding: 5px !default;
