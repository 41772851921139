.btn-group {
  form{
    float:left;
  }

  .btn-button {
    background: none;
  }
}

.btn {
  text-transform: capitalize;
  &.active, &:active {
    box-shadow: none;
  }
}

.btn-plain {
  background: none;
  padding: 5px 0;
}

.brand-sub-1 {
  background: $brand-sub-1;
}
.brand-sub-2 {
  background: $brand-sub-2;
}
.brand-sub-3 {
  background: $brand-sub-3;
}