
// Tags

$tag-default-bg:            $gray-light !default;
$tag-primary-bg:            $brand-primary !default;
$tag-success-bg:            $brand-success !default;
$tag-info-bg:               $brand-info !default;
$tag-warning-bg:            $brand-warning !default;
$tag-danger-bg:             $brand-danger !default;

$tag-color:                 #fff !default;
$tag-link-hover-color:      #fff !default;
$tag-font-size:             75% !default;
$tag-font-weight:           bold !default;
$tag-padding-x:             .4em !default;
$tag-padding-y:             .4em !default;

$tag-pill-padding-x:        .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius:    10rem !default;