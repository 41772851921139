
// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem !default;
$btn-padding-y:                  .5rem !default;
$btn-line-height:                1.25 !default;
$btn-font-weight:                normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba(0,0,0,.125) !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               #fff !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

$btn-padding-x-sm:               .5rem !default;
$btn-padding-y-sm:               .25rem !default;

$btn-padding-x-lg:               1.5rem !default;
$btn-padding-y-lg:               .75rem !default;

$btn-block-spacing-y:            .5rem !default;
$btn-toolbar-margin:             .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;