.modal-content {
  background: $body-bg;
  border: none;
  box-shadow: none;

  .modal-footer {
    border: none;
    padding: 0;
    .btn {
      border: none;
      float: left;
      width: 50%;
      margin: 0;
      padding: 10px;
    }
   .btn + .btn{
     margin:0;
   }
  }

}