
.table {
  max-width: auto;

  tbody > tr > td, thead > tr > th, thead > tr.active > th  {
    border: none;
    position: relative;
    vertical-align: middle;
    &.th-actions {
      text-align: right;
    }
  }

  .title {
    font-weight: 400;
  }

  thead > tr > th, thead > tr.active > th {
    background: $white;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
    font-size: 11px;

    &:first-child {
      padding-left:0;
    }
  }

  .handle, .publish, .select {
    width: 30px
  }
  .publish {
    float: left;
  }

  .th-actions, .manage, .actions {
    text-align: right;
  }

  .selected {
    td {
      background: $menu-hover;
    }
  }

  .sortable-chosen {
    td {
      background: rgba($menu-hover, .7);
    }
  }

  .Monday, .Thursday, .Sunday  {
    background-color:#EFEFEF;
  }
  .Tuesday, .Friday{
    background-color:#E4E4E4;
  }
  .Wednesday, .Saturday{
    background-color:#CECECE;
  }

}

.large-table{
  @include media-breakpoint-up(1350) {
    min-width:1020px;
  }
}

.start{
  border-top: 10px solid $absolute-white;
}

#oncall{
  .table{
    tr{
      border-bottom:1px solid $absolute-white;
    }
    tbody > tr > td, thead > tr > th, thead > tr.active > th  {
      vertical-align: top;
    }
  }
}

