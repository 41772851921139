
// Progress bars

$progress-bg:                 #eee !default;
$progress-bar-color:          #0074d9 !default;
$progress-border-radius:      $border-radius !default;
$progress-box-shadow:         inset 0 .1rem .1rem rgba(0,0,0,.1) !default;

$progress-bar-bg:             $brand-primary !default;
$progress-bar-success-bg:     $brand-success !default;
$progress-bar-warning-bg:     $brand-warning !default;
$progress-bar-danger-bg:      $brand-danger !default;
$progress-bar-info-bg:        $brand-info !default;


// Image thumbnails

$thumbnail-padding:           .25rem !default;
$thumbnail-bg:                $body-bg !default;
$thumbnail-border-width:      $border-width !default;
$thumbnail-border-color:      #ddd !default;
$thumbnail-border-radius:     $border-radius !default;
$thumbnail-box-shadow:        0 1px 2px rgba(0,0,0,.075) !default;


// Figures

$figure-caption-font-size: 90% !default;


// Media objects

$media-margin-top:            15px !default;
$media-heading-margin-bottom:  5px !default;
$media-alignment-padding-x:   10px !default;


// Carousel

$carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6) !default;

$carousel-control-color:                      #fff !default;
$carousel-control-width:                      15% !default;
$carousel-control-sm-up-size:                 30px !default;
$carousel-control-opacity:                    .5 !default;
$carousel-control-font-size:                  20px !default;

$carousel-indicators-width:                   60% !default;

$carousel-indicator-size:                     10px !default;
$carousel-indicator-active-size:              12px !default;
$carousel-indicator-active-bg:                #fff !default;
$carousel-indicator-border-color:             #fff !default;

$carousel-caption-width:                      70% !default;
$carousel-caption-sm-up-width:                60% !default;
$carousel-caption-color:                      #fff !default;

$carousel-icon-width:                         20px !default;