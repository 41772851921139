.nav-tabs {
  margin: 0;
  padding: 0 40px;
  text-transform: capitalize;
  @include clearfix();

  .nav-link {
    display: block;
    padding: 10px;
    border: 1px solid transparent;

    &:focus,
    &:active,
    &:hover {
      border-color: $nav-tabs-active-link-hover-color $nav-tabs-active-link-hover-color transparent;
    }
  }

  .nav-link.active {
    background-color: $body-bg;
    border-color: $nav-tabs-border-color $nav-tabs-border-color transparent;
  }
}