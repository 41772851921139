.card {
  overflow: hidden;

  strong {
    font-weight: bold;
  }
}

.card-block .rotate {
  z-index: 8;
  float: right;
  height: 100%;
}

.card-block .rotate i {
  color: rgba(255, 255, 255, 0.15);
  position: absolute;
  left: 0;
  left: auto;
  right: -10px;
  bottom: 0;
  display: block;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
}



.card {
  position: relative;
  display: block;
  margin-bottom: ($gutter * 2);
  background-color: $absolute-white;
  border-radius: .25rem;
  border: none;

  .table {
    margin-bottom: 0;
    thead > tr > th {
      background: $absolute-white;
    }
  }
  &.card-inverse {
    padding: 0;
    .card-block {
      padding: 12px;
    }
  }

  &.card-primary {
    @include card-variant($brand-primary, $brand-primary);
  }
  &.card-success {
    @include card-variant($brand-success, $brand-success);
  }
  &.card-info {
    @include card-variant($brand-info, $brand-info);
  }
  &.card-warning {
    @include card-variant($brand-warning, $brand-warning);
  }
  &.card-danger {
    @include card-variant($brand-danger, $brand-danger);
  }
.list-group {
  &.margin-b {
    margin-bottom: ($gutter * 2);
  }
}
  .list-group-item {
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    margin-bottom: 0;
  }
}

.card-header, .card-block, .card-footer {
  padding: $gutter;
}
.card-footer {
  border-top: 0;
  background: none;
}

.card-header {
  color: $white;
  font-size: 16px;
  background: $menu-bg;
  .fa {
    color: $absolute-white;
  }
}


.card-img-top {
  border-top-right-radius: calc(.25rem - 1px);
  border-top-left-radius: calc(.25rem - 1px);
}