.sidemenu {
  background: $menu-bg;
  color: $navbar-default-color;
  display: block;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  a {
    color: $white;
    display: block;
    text-decoration: none;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    @include clearfix();

    &:hover {
      color: $menu-hover;
    }
  }
  .fa {
    width: 14px;
    margin-right: 12px;
    text-align: center;
  }

  .header {
    background: $menu-header-bg;
    padding: 20px 20px 20px 27px;
    height: 70px;
    text-align: center;
  }

  .footer {
    background: $menu-header-bg;
    padding: 10px;
    height: 35px;
  }

  .ic {
    display: block;
    float: left;
    margin-right: 15px;
  }

  .active {
    color: $menu-hover;
    background: lighten($menu-header-bg, 3);
  }

  nav {
    padding: 20px 0;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      ul{
        padding-left:12px;
      }
    }

    li {
      display: block;
    }

    a {
      padding: 10px 20px 10px 27px;
    }

    span {
      display: block;
      float: left;
      margin: -2px 0 0 0;
    }

  }

  @include media-breakpoint-up(lg) {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    width: 20%;

    .closed & {
      width: 70px;

      nav {
        span {
          display: none;
        }
      }
    }

    .footer {
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    width: 250px;
  }

}