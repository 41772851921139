.top-bar {
  padding: 16px 20px 12px 20px;
  @include clearfix();

  .title {
    margin: 0;
    text-transform: capitalize;
    float: left;
  }
  nav {
    float: right;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media (min-width: $bp-med) {
    background: #fff;
    height: 70px;
    padding: 20px 40px;
    width: 100%;
  }
}