// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-x:             1.25rem !default;
$alert-padding-y:             .75rem !default;
$alert-border-radius:         $border-radius !default;
$alert-link-font-weight:      bold !default;
$alert-border-width:          $border-width !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;