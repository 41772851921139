
.identity {
  float: left;
  margin: 0;
  text-decoration: none;
  font-size: 20px;
  @extend .sprite;

  text-indent: -9999px;

  .logo {
    display: inline-block;
    margin: 0 11px 0 0;
    width: 100px;
  }

  .closed &{
    span {
      display: none;
    }
  }

  &.large {
    .logo {
      margin:40px auto 0 auto;
      width: 130px;
    }
  }
}

.auth {
  .identity {
    height: 61px;
    width: 130px;
    background-position: -20px -310px;
  }
}

.sidemenu {
  .identity {
    height: 40px;
    width: 140px;
    background-position: -20px -320px;
  }
}