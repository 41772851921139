.v-select {
  position: relative;

  .open-indicator {
    position: absolute;
    bottom: 17px !important;
    right: 10px;
    display: inline-block;
    cursor: pointer;
    pointer-events: all;
    transition: all 150ms cubic-bezier(1.000, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
    opacity: 1;
    transition: opacity .1s;
    height: 10px !important;
    width: 10px !important;
  }
  &.loading .open-indicator {
    opacity: 0;
  }
  .open-indicator:before {
    border-color: rgba(60, 60, 60, .5);
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    vertical-align: top;
    transform: rotate(133deg);
    transition: all 150ms cubic-bezier(1.000, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
  }
  &.open .open-indicator {
    bottom: 17px !important;
  }
  &.open .open-indicator:before {
    transform: rotate(315deg);
  }
  .dropdown-toggle {
    display: block;
    padding: 0;
    background: none;
    border: 1px solid $input-border-color !important;
    border-radius: 0 !important;
    min-height: 41px;
    white-space: normal;
    -webkit-appearance: inherit;
  }
  &.searchable .dropdown-toggle {
    cursor: text;
  }
  &.open .dropdown-toggle {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .dropdown-menu {
    margin: 0;
    width: 100%;
    overflow-y: scroll;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    a {
      padding: 6px;
      margin: 0 6px 6px 6px;
      display: block;
    }
    .text-center {
      text-align: center;
    }
  }
  .selected-tag {
    color: #333;
    background-color: #f0f0f0;
    border: none !important;
    border-radius: 0 !important;
    height: 27px !important;
    margin: 6px 0 0 6px !important;
    padding: 0 .5em !important;
    float: left;
    line-height: 1.9em !important;
  }
  .selected-tag .close {
    float: none;
    margin-right: 0;
    font-size: 20px;
  }
  input[type=search], input[type=search]:focus {
    display: inline-block;
    border: none;
    outline: none;
    margin: 0;
    padding: 0 .5em;
    width: 10em;
    max-width: 100%;
    background: none;
    position: relative;
    box-shadow: none;
    float: left;
    clear: none;
    min-height: 41px;
  }
  input[type=search]:disabled {
    cursor: pointer;
  }
  li a {
    cursor: pointer;
  }
  .active a {
    background: rgba(50, 50, 50, .1);
    color: #333;
  }
  .highlight a, .v-select li:hover > a {
    background: #f0f0f0;
    color: #333;
  }
  .spinner {
    opacity: 0;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 5px;
    text-indent: -9999em;
    overflow: hidden;
    border-top: .9em solid rgba(100, 100, 100, .1);
    border-right: .9em solid rgba(100, 100, 100, .1);
    border-bottom: .9em solid rgba(100, 100, 100, .1);
    border-left: .9em solid rgba(60, 60, 60, .45);
    transform: translateZ(0);
    animation: vSelectSpinner 1.1s infinite linear;
    transition: opacity .1s;
  }
  &.loading .spinner {
    opacity: 1;
  }
  .spinner, .spinner:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
}

@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}