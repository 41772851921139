.alert-holder{
  position: absolute;
  top: 15px;
  right: 40px;
  z-index:1000;

  .alert {
    padding: 10px;
  }


}