$gutter: 12px;

// Breakpoints
// ------------------------------------------------------------

// Start with the small screen first, then expand until it looks like shit. Time for a breakpoint! -Stephen Hay


$bp-small 									: 576px;
$bp-med										: 768px;
$bp-large									: 992px;
$bp-xl										: 1200px;
$bp-max                                     : $bp-xl;


$container-small                            : 540px;
$container-medium                           : 720px;
$container-large                            : 960px;
$container-xl                               : 1140px;

$grid-columns:               12 !default;
$grid-gutter-width-base:     30px !default;
$grid-gutter-widths: (
        xs: $grid-gutter-width-base,
        sm: $grid-gutter-width-base,
        md: $grid-gutter-width-base,
        lg: $grid-gutter-width-base,
        xl: $grid-gutter-width-base
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: $bp-small,
        md: $bp-med,
        lg: $bp-large,
        xl: $bp-xl
) !default;

$container-max-widths: (
        sm: $container-small,
        md: $container-medium,
        lg: $container-large,
        xl: $container-xl
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        1: (
                x: $spacer-x,
                y: $spacer-y
        ),
        2: (
                x: ($spacer-x * 1.5),
                y: ($spacer-y * 1.5)
        ),
        3: (
                x: ($spacer-x * 3),
                y: ($spacer-y * 3)
        )
) !default;
$border-width: 1px !default;


