
// Forms

$input-padding-x:                .75rem !default;
$input-padding-y:                .5rem !default;
$input-line-height:              1.25 !default;

$input-bg:                       #fff !default;
$input-bg-disabled:              $gray-lighter !default;

$input-color:                    $gray !default;
$input-border-color:             rgba(0,0,0,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba(0,0,0,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-bg-focus:                 $input-bg !default;
$input-border-focus:             #66afe9 !default;
$input-box-shadow-focus:         $input-box-shadow, 0 0 8px rgba(102,175,233,.6) !default;
$input-color-focus:              $input-color !default;

$input-color-placeholder:        #999 !default;

$input-padding-x-sm:             .5rem !default;
$input-padding-y-sm:             .25rem !default;

$input-padding-x-lg:             1.5rem !default;
$input-padding-y-lg:             .75rem !default;

$input-height:                   (($font-size-base-rem * $line-height-base) + ($input-padding-y * 2)) !default;
$input-height-lg:                (($font-size-lg-rem * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm:                (($font-size-sm-rem * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

$form-group-margin-bottom:       $spacer-y !default;

$input-group-addon-bg:           $gray-lighter !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled:                not-allowed !default;

$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-spacer-y: .25rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg:         #ddd !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba(0,0,0,.1) !default;

$custom-control-disabled-cursor:             $cursor-disabled !default;
$custom-control-disabled-indicator-bg:       #eee !default;
$custom-control-disabled-description-color:  #767676 !default;

$custom-control-checked-indicator-color:      #fff !default;
$custom-control-checked-indicator-bg:         #0074d9 !default;
$custom-control-checked-indicator-box-shadow: none !default;

$custom-control-focus-indicator-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9 !default;

$custom-control-active-indicator-color:      #fff !default;
$custom-control-active-indicator-bg:         #84c6ff !default;
$custom-control-active-indicator-box-shadow: none !default;

$custom-checkbox-radius: $border-radius !default;
$custom-checkbox-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

$custom-checkbox-indeterminate-bg: #0074d9 !default;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color !default;
$custom-checkbox-indeterminate-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E") !default;
$custom-checkbox-indeterminate-box-shadow: none !default;

$custom-radio-radius: 50% !default;
$custom-radio-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E") !default;

$custom-select-padding-x:          .75rem  !default;
$custom-select-padding-y:          .375rem !default;
$custom-select-indicator-padding:  1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color:          $input-color !default;
$custom-select-disabled-color: $gray-light !default;
$custom-select-bg:            #fff !default;
$custom-select-disabled-bg:   $gray-lighter !default;
$custom-select-bg-size:       8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator:     url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") !default;
$custom-select-border-width:  $input-btn-border-width !default;
$custom-select-border-color:  $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: #51a7e8 !default;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(81, 167, 232, .5) !default;

$custom-select-sm-padding-y: .2rem !default;
$custom-select-sm-font-size: 75% !default;

$custom-file-height:           2.5rem !default;
$custom-file-width:            14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9 !default;

$custom-file-padding-x:     .5rem !default;
$custom-file-padding-y:     1rem !default;
$custom-file-line-height:   1.5 !default;
$custom-file-color:         #555 !default;
$custom-file-bg:            #fff !default;
$custom-file-border-width:  $border-width !default;
$custom-file-border-color:  #ddd !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow:    inset 0 .2rem .4rem rgba(0,0,0,.05) !default;
$custom-file-button-color:  $custom-file-color !default;
$custom-file-button-bg:     #eee !default;
$custom-file-text: (
        placeholder: (
                en: "Choose file..."
        ),
        button-label: (
                en: "Browse"
        )
) !default;


// Form validation icons
$form-icon-success-color: $brand-success !default;
$form-icon-success: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") !default;

$form-icon-warning-color: $brand-warning !default;
$form-icon-warning: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E") !default;

$form-icon-danger-color: $brand-danger !default;
$form-icon-danger: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") !default;