.key {
  display: block;
  float:right;

  ul {
    list-style: none
  }
  li {
    display: inline-block;
    padding: ($gutter /2);
    margin: ($gutter /2);
    cursor: pointer;
    background-color: #ececec;
  }
  p{
    float: left;
    margin: 0;
  }
  .key-icon {
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 ($gutter/2) 0 0;
  }
}