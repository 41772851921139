
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;
$table-bg-accent:               rgba(0,0,0,.05) !default;
$table-bg-hover:                rgba(0,0,0,.075) !default;
$table-bg-active:               $table-bg-hover !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;
