html, body {
  font-weight: 300;

  @media (min-width: $bp-med) {
    height: 100%;

    .form {
      height: 100%;
      overflow: hidden;
    }
  }
}

[v-cloak] {
  display: none;
}

.wrapper {
  @media (min-width: $bp-med) {
    height: 100%;
  }
}