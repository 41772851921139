.media {
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0;
    padding: 20px;
    text-align: left;
    position: relative;
  }
}

figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px 0;
  background: $menu-bg;
  color: $menu-hover;
  h3 {
    margin: 0;
    padding: 0;
    color: $white;
  }
  a {
    text-align: center;
    padding: 6px 12px;
    display: inline-block;
    color: $white;
    &:hover {
      color: $menu-hover;
    }
  }
}

.media {
  figure {
    overflow: hidden;
    margin: 0;
    position: relative;

    figcaption {
      width: 100%;
      top: auto;
      bottom: 0;
      text-align: center;
      opacity: 0;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
      -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
      transition: transform 0.4s, opacity 0.1s 0.3s;
    }

    img {
      max-width: 100%;
      width: 100%;
      display: block;
      position: relative;
      -webkit-transition: -webkit-transform 0.4s;
      -moz-transition: -moz-transform 0.4s;
      transition: transform 0.4s;
    }
    &:hover {
      figcaption {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
        -moz-transition: -moz-transform 0.4s, opacity 0.1s;
        transition: transform 0.4s, opacity 0.1s;
      }
      img {
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
      }
    }

  }
}

.tiny {
  font-size: 12px;
}