
// List group

$list-group-bg:                 #fff !default;
$list-group-border-color:       #ddd !default;
$list-group-border-width:       $border-width !default;
$list-group-border-radius:      $border-radius !default;

$list-group-hover-bg:           #f5f5f5 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color:      $gray-light !default;
$list-group-disabled-bg:         $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

$list-group-item-padding-x:             1.25rem !default;
$list-group-item-padding-y:             .75rem !default;
$list-group-item-heading-margin-bottom: 5px !default;