.interface {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  position: relative;

  .content {
    padding: 12px 20px;

    strong {
      font-weight: bold;
    }
  }

  .tab-content {
    padding: 12px 20px;

    strong {
      font-weight: bold;
    }
  }

  @include media-breakpoint-up(md) {
    height: 100%;

    .manage {
      height: calc(100% - 120px);
      overflow: scroll;
    }

    .content {
      padding: 20px 40px;
      height: 100%;
      overflow: auto;

      .no-content {
        margin: 120px auto 0 auto;
        width: 230px;
      }
    }

    .content-tabbed {
      background: $absolute-white;
      padding: 20px 0 0 0;
      height: 100%;
      position: relative;
      overflow: hidden;

      > div {
        &:first-child {
          height: 100%;
          overflow: hidden;
        }
      }

      .tab-content {
        height: calc(100% - 60px);
        overflow: hidden;
        background: $white;
        padding: 20px 0;
      }

      .tab-pane {
        height: 100%;
        overflow: auto;
        padding: 0 40px;
        @include clearfix();
      }
    }

    .closed & {
      margin: 0 0 0 70px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 0 0 0 20%;
  }
  @include media-breakpoint-up(xl) {
    margin: 0 0 0 250px;
  }
}

.toolbar{
  ul{
    list-style: none;
    float: left;
    width: 100%;
    margin: 12px 0;
    padding: 0;
  }
  li{
    display: inline-block;
    float: left;
  }
}