// -------------------------------------------------------
// Media queries
// -------------------------------------------------------


// Width Based Tools

// Reference widths from settings file
@mixin respond-to($media-min, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (min-width: em-font($media-min))  {
      @content;
    }
  }
  @else {
    @media screen and (min-width: em-font($media-min)) {
      @content;
    }
  }
}

@mixin respond-to-max($media-max, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (max-width: em-font($media-max - 1px))  {
      @content;
    }
  }
  @else {
    @media screen and (max-width: em-font($media-max - 1px)) {
      @content;
    }
  }
}


@mixin respond-to-min-max($media-min, $media-max, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (min-width: em-font($media-min)) and (max-width: em-font($media-max))  {
      @content;
    }
  }
  @else {
    @media screen and (min-width: em-font($media-min)) and (max-width: em-font($media-max)) {
      @content;
    }
  }
}


// Height Based Tools

@mixin respond-to-height($media-min, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (min-height: $media-min)  {
      @content;
    }
  }
  @else {
    @media screen and (min-height: $media-min) {
      @content;
    }
  }
}

@mixin respond-to-max-height($media-max, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (max-height: ($media-max - 1px))  {
      @content;
    }
  }
  @else {
    @media screen and (max-height: ($media-max - 1px)) {
      @content;
    }
  }
}

@mixin respond-to-min-max-height($media-min, $media-max, $IE9: true) {
  @if $IE9 == true {
    .lt-ie9 & {
      @content;
    }
    @media screen and (min-height: $media-min) and (max-height: $media-max)  {
      @content;
    }
  }
  @else {
    @media screen and (min-height: $media-min) and (max-height: $media-max) {
      @content;
    }
  }
}


// -------------------------------------------------------
// Retina images
// -------------------------------------------------------

// Usage: Send path, image name, and width and height.
// Name retina images [name]2x.

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}