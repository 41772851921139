
.controls {
  background: rgba($menu-bg-lighter, .5);
  position: relative;

  @media (min-width: $bp-med) {
    height: 50px;

    .actions {
      padding: 10px 40px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.footer-controls {
  bottom: 20px;
  position: absolute;
  right: 40px;
}