.dash {
  display: block;
  padding-bottom: 24px;
  @include clearfix();

  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }

  .quantity, .status {
    display: block;
    line-height: normal;
  }

  .quantity {
    font-size: 40px;
  }
  .status {
    font-size: 15px;
  }

  .card {

    .bg-info {
      background-color: $brand-success;
    }
    .bg-success {
      background-color: $brand-sub-2;
    }
    .bg-warning {
      background-color: $brand-sub-1;
    }
    .bg-danger {
      background-color: $brand-sub-3;
    }
  }
}