
.editor{
  position:relative;
  .toolbar{
    padding: 0 0.75rem;
    border-top: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow:hidden;
    display:block;
    button{
      background: none;
      border: none;
      padding: 0 12px;
      height: 26px;
    }
  }
  .editarea {
    min-height: 300px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: block;
    margin-bottom: 1.5rem;
    width: 100%;
    background: #F5F5F5;
    border-color: #D8D8D8;
    border-style: solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 1px;
    box-shadow: none;
    max-width: 100%;
    padding: 0.75rem;
    -webkit-appearance: none;
  }
  .paper{
    min-height: 600px;
    background-color: #FFF;
    padding: 12px;
    border: 1px solid #ececec;
  }
}