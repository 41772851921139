// Tooltips

$tooltip-max-width:           200px !default;
$tooltip-color:               #fff !default;
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;
$tooltip-padding-y:           3px !default;
$tooltip-padding-x:           8px !default;
$tooltip-margin:              3px !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;